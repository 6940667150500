import React, { useEffect } from "react";

const AutoAds = () => {
  useEffect(() => {
    const adsScript = document.createElement("script");
    adsScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3324920727589405";
    adsScript.async = true;
    document.body.appendChild(adsScript);

    const initAds = () => {
      if (window.adsbygoogle) {
        window.adsbygoogle.push({
          google_ad_client: "ca-pub-3324920727589405",
          enable_page_level_ads: true,
        });
      }
    };

    adsScript.onload = initAds;

    return () => {
      document.body.removeChild(adsScript);
    };
  }, []);

  return null; // This component does not render any visible UI.
};

export default AutoAds;
